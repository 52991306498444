.account-page {
    .toggle-workflow-approver {
        padding-left: 30px;
        padding-top: 40px;
    }
    .toggle-support-user {
        padding-top: 40px;
    }

    #account-csv-setting-modal {
        .modal-account-header {
            padding: 0;
        }

        .modal-account-title {
            padding: 16px;
        }

        .modal-account-description {
            padding: 9px 25px;
        }

        #account-csv-setting-modal-csv-error {
            width: 100%;
            max-height: 150px;
            list-style-type: none;
            color: $danger;
            font-size: 0.9rem;
            overflow-y: scroll;
            border: 1px solid $gray-500;
        }

        #account-csv-setting-modal-csv-submit-message {
            color: $green;
            font-size: 0.9rem;
        }
    }

    #account-csv-description-modal {
        .modal-dialog {
            max-height: 840px;
        }

        .modal-content {
            height: 840px;
        }
    }
    
    .password_form {
        .input-group .input-group-text {
            background-color: transparent;
            border-bottom-right-radius: 0.25rem !important;
            border-left: 0;
            border-top-right-radius: 0.25rem !important;
            color: #777;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }

        .input-group .form-control {
            border-right: 0;
        }

        .show-password-btn:hover {
            cursor: pointer;
        }
    }
}